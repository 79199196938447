<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" />
  <div class="main_right">
    <div class="wrapper">
      <div class="search" v-if="searchOptions.items && searchOptions.items.length">
        <vxe-form v-bind="searchOptions" @submit="searchEvent" @reset="resetEvent"></vxe-form>
      </div>
      <div class="table">
        <vxe-grid ref="xGrid" height="auto" v-bind="gridOptions" :loading="loading" :data="tableData" :pager-config="tablePage"></vxe-grid>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      loading: false,
      currNode: {},
      selectRow: null,
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 10
      },
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          export: true,
          refresh: {
            query: () => {
              this.getTableColumns();
            },
          },
        },
        // 列表字段配置
        columns: [],
        editConfig: {
          enabled: false
        },
        exportConfig: {},
        importConfig: {},
        printConfig: {}
        // showFooter: true,
      },
      tableData: [],
      formOptions: {
        // 新增功能字段配置
        items: [],
        //表单校验规则
        rules: {},
        // 新增功能字段初始化
        data: {},
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "roomName",
            title: "房屋编号",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入房屋编号",
              },
            },
          },
          {
            field: "userName",
            title: "用户名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入用户名称",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          roomName: "",
          userName: ""
        },
      },
    };
  },
  methods: {
    ...mapActions(['getListService', 'findByEventService']),

    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.searchOptions.data["parentId"] = node.id;
      this.getQuestionnaireEvent(node);
    },

    // 获取整个流调问卷题目
    getQuestionnaireEvent(node) {
      this.findByEventService({
          service: "/xifang/questionnaire",
          params: {
            parentId: node.id
          }
        })
        .then(({ code, data }) => {
          if (code == 200 && data.length) {
            this.selectRow = data[0];
            this.getTableColumns()
          }
        });
    },

    // 获取动态列
    getTableColumns() {
      this.loading = true;
      this.getListService({
        service: `/xifang/questionnaireDetail`,
        params: {
          questionnaireId: this.selectRow.id
        },
        tablePage: {
          total: 0,
          currentPage: 1,
          pageSize: 999
        },
        dictTypeCodes: []
      }).then(({ code, data }) => {
        if (code == 200) {
          let columns = [{
              type: "seq",
              width: 60
            },
            {
              type: "checkbox",
              width: 60
            },
            {
              field: 'communityName',
              title: '小区名称',
              minWidth: 120,
            },
            {
              field: 'roomName',
              title: '房屋编号',
              minWidth: 100,
            },
            {
              field: 'userName',
              title: '用户名称',
              minWidth: 100,
            },
            {
              field: 'userPhone',
              title: '联系方式',
              minWidth: 100,
            },
          ];
          data.records.forEach(item => {
            let editRender = {
              name: "$input",
              props: {
                placeholder: item.placeholder
              }
            }
            if (item.type == 2 || item.type == 3) {
              editRender = {
                name: "$select",
                options: JSON.parse(item.optionList),
                props: {
                  multiple: item.type == 3 ? true : false,
                  placeholder: item.placeholder
                }
              }
            }
            columns.push({
              field: item.id,
              title: item.title,
              minWidth: 160,
              editRender: editRender
            })
          });
          this.gridOptions.columns = columns;
          // 获取数据
          this.getLists();
        }
        this.resetEvent();
      }).catch((err) => {
        this.resetEvent();
      });
    },

    // 获取列对应值
    getLists() {
      this.loading = true;
      this.getListService({
        service: `/xifang/questionnaireAnswer`,
        params: this.searchOptions.data || {},
        tablePage: {
          total: 0,
          currentPage: 1,
          pageSize: 10
        },
        dictTypeCodes: []
      }).then(({ code, data }) => {
        if (code == 200) {
          data.records.map((item, index) => {
            if (item.content.length) {
              this.listValueMap(item)
            }
          });
          this.tableData.total = data.total;
          this.tableData = data.records;
        }
        this.resetEvent();
      }).catch((err) => {
        this.resetEvent();
      });
    },

    // 数据映射
    listValueMap(list) {
      list.content.forEach(obj => {
        let column = this.$XEUtils.filterTree(this.gridOptions.columns, item => item.field == obj.questionnaireDetailId)[0] || {};
        if (column && column.field) {
          list[column.field] = obj.value;
        }
      });
      return list;
    },
    // 搜索功能
    searchEvent() {
      this.getTableColumns();
    },
    resetEvent() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
